<template>
  <v-dialog :value="isShown" max-width="342" @input="closeDialog()">
    <v-card class="d-flex flex-column align-end pa-3">
      <v-icon size="18" @click="closeDialog()">
        $close-outlined
      </v-icon>
      <v-sheet class="pt-2 pb-4 px-5" width="100%">
        <p class="text-center text-subtitle-1 font-weight-medium mb-3">
          {{ currentPlanTitle }}
        </p>
        <div class="d-flex align-center justify-center mb-6">
          <span v-if="currentPlan" class="block mr-3 text-subtitle-1 custom-title-plan" :style="`color: ${currentPlan?.textColor}`" v-html="currentPlan?.pickCurrentLocale(locale).name" />
          <div v-if="currentPlan" class="rotate-arrow global-relative">
            <v-icon :size="20" class="d-flex align-center justify-center">
              $arrow-with-stick
            </v-icon>
          </div>
          <span class="block ml-3 text-subtitle-1 custom-title-plan" :style="`color: ${selectedPlan?.textColor}`" v-html="selectedPlan?.pickCurrentLocale(locale).name" />
        </div>
        <p class="text-center text-subtitle-2 font-weight-regular mb-6">
          {{ currentPlanDescription }}
        </p>
        <p v-if="false" class="text-center font-weight-medium text-subtitle-1 mb-0">
          {{ $t('providers.tariff_funds.description', { value: '' }) }}
        </p>
      </v-sheet>
      <v-sheet class="px-8 pb-6" width="100%">
        <c-btn
          block
          color="primary"
          :height="46"
          class="text-subtitle-1 font-weight-medium"
          :label="$t('providers.tariff_funds.top_up_balance')"
          :loading="isLoading"
          @click="$emit('add-funds')"
        />
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import { mapState } from 'vuex'

  export default {
    name: 'SubscribeDialog',
    components: { CBtn },
    props: {
      isLoading: {
        type: Boolean,
        required: true
      },
      isShown: {
        type: Boolean,
        required: true
      },
      availablePlans: {
        type: Array,
        required: true
      },
      currentPlanId: {
        type: Number,
        required: true
      },
      selectedPlanId: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapState('settings', ['locale']),
      currentPlanTitle() {
        return this.currentPlan ? this.$t('providers.tariff_subscribe.change_tariff') : this.$t('providers.tariff_subscribe.buy_tariff')
      },
      currentPlanDescription() {
        return this.currentPlan ? this.$t('providers.tariff_funds.low_balance') : this.$t('providers.tariff_funds.low_buy_balance')
      },
      currentPlan() {
        return this.availablePlans.find((item) => item.id === this.currentPlanId)
      },
      selectedPlan() {
        return this.availablePlans.find((item) => item.id === this.selectedPlanId)
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog')
      }
    }
  }
</script>

<style lang="scss" scoped>

.rotate-arrow{
  transform: rotate(-90deg);
}

.custom-title-plan {
  & ::v-deep(strong) {
    font-weight: 500;
  }
}

::v-deep .rotate-arrow svg{
  position: absolute;
  left: 5px;
}

</style>
