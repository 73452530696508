<template>
  <div class="d-flex justify-center border-btn rounded py-3 py-sm-4">
    <a
      class="text-center text-decoration-underline info--text"
      :href="helpUrls.traffic_dsp_help"
      target="_blank"
    >
      {{ $t('providers.how_to_use') }}
    </a>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'HelpButton',
    computed: {
      ...mapGetters('settings', ['helpUrls'])
    }
  }
</script>

<style lang="scss" scoped>
  .border-btn {
    max-width: 848px !important;
    margin: 0 auto;
    border: 1px solid;
    border-color: var(--v-info-base);
  }
</style>
