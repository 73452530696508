<template>
  <v-dialog :value="isShown" max-width="342" @input="closeDialog()">
    <v-card class="d-flex flex-column align-end pa-3">
      <v-icon size="18" @click="closeDialog()">
        $close-outlined
      </v-icon>
      <div class="pt-2 pb-4 px-5">
        <p class="text-center text-subtitle-1 font-weight-medium mb-6">
          {{ $t('providers.tariff_unsubscribe.cancel_subscription') }}
        </p>
        <p class="text-center text-subtitle-2 mb-6 px-5 font-weight-regular">
          {{ $t('providers.tariff_unsubscribe.description') }}
        </p>
        <p class="text-center font-weight-medium text-subtitle-1 mb-0">
          {{ $t('providers.tariff_unsubscribe.confirm') }}
        </p>
      </div>
      <v-sheet width="100%" class="d-flex justify-center align-center pb-4">
        <c-btn
          color="secondary"
          depressed
          :width="103"
          :height="46"
          class="black--text"
          :label="$t('providers.tariff_unsubscribe.yes')"
          :loading="isLoading"
          @click="$emit('confirm')"
        />
        <c-btn
          color="primary"
          outlined
          :width="103"
          :height="46"
          :label="$t('providers.tariff_unsubscribe.back')"
          class="ml-4"
          @click="closeDialog()"
        />
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'

  export default {
    name: 'UnsubscribeDialog',
    components: { CBtn },
    props: {
      isShown: {
        type: Boolean,
        required: true
      },
      isLoading: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        subscriptionIsLoading: false
      }
    },
    methods: {
      closeDialog() {
        this.$emit('close-dialog')
      }
    }
  }
</script>
