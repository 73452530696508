<template>
  <div>
    <div class="px-7 px-md-0">
      <help-button v-if="helpUrls.traffic_dsp_help" />
    </div>
    <div class="text-center mb-5 mb-sm-7 mt-8 mt-sm-15 font-weight-bold">
      {{ $t('providers.choose_plan') }}
    </div>
    <div class="d-flex justify-center align-stretch flex-wrap">
      <v-card
        v-for="(promoPlan, index) of availablePlans"
        :key="promoPlan.id"
        :width="isMobile && index === 1 ? '296px' : '272px'"
        :class="[
          'card-plan d-flex flex-column align-center pt-3 pt-sm-5 pb-4 px-4 px-sm-6 global-relative',
          index === 1 ? 'mx-4' : 'my-3',
          {
            'border-primary mt-5 mt-sm-0': userActiveTariffPlanId === promoPlan.id
          },
          {
            'mt-9 mt-sm-0': userActiveTariffPlanId === promoPlan.id && index !== 1
          }
        ]"
      >
        <div v-if="userActiveTariffPlanId === promoPlan.id" class="current-plan font-weight-medium text-subtitle-1 d-flex align-center">
          <v-icon color="primary" :size="18">
            $check
          </v-icon>
          <span class="ml-2">{{ $t('providers.your_tariff_plan_now') }}</span>
        </div>
        <div :class="['mb-8 px-1 px-sm-0', { 'd-flex align-center ': !isMdAndUp }]">
          <div
            class="custom-title-plan font-weight-black text-center"
            :style="`color: ${promoPlan.textColor}`"
          >
            {{ promoPlan.transformMaxRequestsPerSecond }}
          </div>
          <div :class="['text-body-2 font-weight-medium secondary-darken--text', { 'ml-4': !isMdAndUp }]">
            {{ $t('providers.request_per_second') }}
          </div>
        </div>
        <div class="d-flex align-center flex-row flex-md-column px-1 px-sm-0">
          <icon-chart-success
            class="primary--text mr-2 mr-md-0 mb-md-3"
            :icon-size="index === 1 && !isMobile ? 44 : 26"
            :progress="index"
          />
          <div class="text-subtitle-1 font-weight-600 custom-title-plan__name" v-html="promoPlan.pickCurrentLocale(locale).name" />
        </div>
        <div class="mb-8 card-plan__list px-1 px-sm-0" v-html="promoPlan.pickCurrentLocale(locale).description" />
        <v-spacer />
        <div class="global-full-width text-center">
          <span class="text-h6 font-weight-black px-1 px-sm-0 mb-1">{{ `$${promoPlan.price}` }}</span>
          <template v-if="userActiveTariffPlanId === promoPlan.id">
            <div class="text-uppercase font-weight-black primary--text mt-5 pt-5 border-top px-1 px-sm-0">
              {{ $t('providers.active_until', { date: userTariffPlanDate }) }}
            </div>
          </template>
          <c-btn
            v-else
            block
            color="primary"
            :height="46"
            :label="$t('providers.buy_now')"
            @click="onBuyButtonClick(promoPlan.id)"
          />
        </div>
      </v-card>
    </div>
    <div class="d-flex flex-column align-center mt-6 mt-sm-10">
      <span>{{ $t('providers.not_found_suits') }}</span>
      <c-btn
        class="text-decoration-underline text-subtitle-1 mt-1"
        text
        :height="22"
        color="primary"
        :label="$t('providers.leave_a_request')"
      />
    </div>
    <subscribe-dialog
      :is-shown="modalIsShown"
      :is-loading="subscriptionIsLoading"
      :available-plans="availablePlans"
      :selected-plan-id="selectedPlan"
      :current-plan-id="userActiveTariffPlanId"
      @close-dialog="modalIsShown = false"
      @confirm="subscribePlan()"
    />
    <low-funds-dialog
      :is-shown="fundsDialogIsShown"
      :available-plans="availablePlans"
      :selected-plan-id="selectedPlan"
      :current-plan-id="userActiveTariffPlanId"
      :is-loading="subscriptionIsLoading"
      @close-dialog="fundsDialogIsShown = false"
      @add-funds="$router.push({ name: routeNames.ADD_FUNDS })"
    />
  </div>
</template>

<script>
  import CBtn from '@clickadilla/components/ui/CBtn.vue'
  import IconChartSuccess from '@clickadilla/components/icons/IconChartSuccess.vue'
  import { mapActions, mapGetters, mapState } from 'vuex'
  import { trafficProviderRepository } from '@/services/repository-factory.js'
  import handleErrors from '@/services/handleErrors.js'
  import SubscribeDialog from './SubscribeDialog.vue'
  import LowFundsDialog from './LowFundsDialog.vue'
  import ProviderPlan from '@/services/classes/ProviderPlan.js'
  import HelpButton from '../../HelpButton.vue'
  import routeNames from '@/types/route-names.js'

  export default {
    name: 'ProvidersPromoPlans',
    components: {
      CBtn,
      IconChartSuccess,
      SubscribeDialog,
      LowFundsDialog,
      HelpButton
    },
    props: {
      hasUpdate: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        routeNames,
        subscriptionIsLoading: false,
        availablePlans: [],
        selectedPlan: 0,
        modalIsShown: false,
        fundsDialogIsShown: false,
        headers: [
          {
            value: 'price'
          }
        ]
      }
    },
    computed: {
      ...mapState('settings', ['locale']),
      ...mapGetters('settings', ['helpUrls']),
      ...mapState('personal', ['trafficProviderSubscription', 'fetchIsLoading']),
      isMobile() {
        return this.$vuetify.breakpoint.xsOnly
      },
      isMdAndUp() {
        return this.$vuetify.breakpoint.mdAndUp
      },
      userActiveTariffPlanId() {
        return this.trafficProviderSubscription?.plan?.id ?? 0
      },
      userTariffPlan() {
        return this.trafficProviderSubscription
      },
      userTariffPlanDate() {
        const parts = this.userTariffPlan.paidUntil.split('-')
        const year = parts[0]
        const month = parts[1]
        const day = parts[2]

        return `${day}.${month}.${year.slice(-2)}`
      }
    },
    async created() {
      await this.fetchPromoPlans()
    },
    methods: {
      ...mapActions('personal', ['fetchUserProfile']),
      async subscribePlan() {
        this.subscriptionIsLoading = true
        try {
          if (this.hasUpdate && this.userActiveTariffPlanId) {
            await trafficProviderRepository.updateSubscribe(this.userTariffPlan.id, this.selectedPlan)
          } else {
            await trafficProviderRepository.subscribe(this.selectedPlan)
          }
          await this.fetchUserProfile()
          if (this.$route.name !== routeNames.PROVIDERS) {
            await this.$router.push({
              name: routeNames.PROVIDERS
            })
          }
        } catch (error) {
          this.modalIsShown = false
          if (error.response && error.response.status === 422) {
            this.fundsDialogIsShown = true
            this.subscriptionIsLoading = false
            return
          }
          handleErrors(error)
        }
        this.subscriptionIsLoading = false
      },
      onBuyButtonClick(planId) {
        this.modalIsShown = true
        this.selectedPlan = planId
      },
      async fetchPromoPlans() {
        try {
          const requestBody = {
            headers: this.headers,
            options: { sortBy: ['price'] }
          }
          const response = await trafficProviderRepository.plans(requestBody)

          this.availablePlans = response?.map((plan) => new ProviderPlan(plan) ?? [])
        } catch (error) {
          handleErrors(error)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

.current-plan {
  position: absolute;
  top: -28px;
}

.card-plan {
  &__list {
    & ::v-deep(ul) {
      list-style-type:none;
      color: var(--v-black-base);
      padding-left: 0;

    }

    & ::v-deep(ul li) {
      width: auto;
      text-align: center;
      font-size: 14px;
      position: relative;
      margin: 0 auto;

      &:before {
        content: '.';
        font-size: 24px;
        line-height: 14px;
        vertical-align: super;
        padding-right: 5px;
      }
    }
  }
}

.font-weight-600 {
  font-weight: 600;
}

.custom-title-plan {
  font-size: 40px;
  line-height: 48px;
  &__name {
    & ::v-deep(strong) {
      font-weight: 600;
    }
  }
}

.border-top {
  border-top: 1px solid var(--v-secondary-base)
}

</style>
